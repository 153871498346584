<template>
  <b-modal
    :id="`modal-lg-${data.item.id}`"
    ok-only
    ok-title="Cerrar"
    ok-variant="outline-secondary"
    centered
    size="xl"
    title="Producto"
    width="900px"
  >
    <productosView
      :data="data"
    />
    <productosHistorial
      :data="data.item"
    />
  </b-modal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'

const productosView = () => import('@/layouts/components/Productos/components/ProductosView.vue')
const productosHistorial = () => import('@/layouts/components/Productos/components/ProductosHistorial.vue')

export default {
  components: {
    BModal,

    productosView,
    productosHistorial,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style>

</style>
